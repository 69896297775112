const regions = [
  {
    sales_region_code: "AS_OC",
    country_code: "AF",
  },
  {
    sales_region_code: "EMEI",
    country_code: "AL",
  },
  {
    sales_region_code: "NO",
    country_code: "AQ",
  },
  {
    sales_region_code: "AF",
    country_code: "DZ",
  },
  {
    sales_region_code: "NA",
    country_code: "AS",
  },
  {
    sales_region_code: "EMEI",
    country_code: "AD",
  },
  {
    sales_region_code: "AF",
    country_code: "AO",
  },
  {
    sales_region_code: "NA",
    country_code: "AG",
  },
  {
    sales_region_code: "EMEI",
    country_code: "AZ",
  },
  {
    sales_region_code: "LSA",
    country_code: "AR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "AU",
  },
  {
    sales_region_code: "EMEI",
    country_code: "AT",
  },
  {
    sales_region_code: "NA",
    country_code: "BS",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "BH",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "BD",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "AM",
  },
  {
    sales_region_code: "NA",
    country_code: "BB",
  },
  {
    sales_region_code: "EMEI",
    country_code: "BE",
  },
  {
    sales_region_code: "NA",
    country_code: "BM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "BT",
  },
  {
    sales_region_code: "LSA",
    country_code: "BO",
  },
  {
    sales_region_code: "EMEI",
    country_code: "BA",
  },
  {
    sales_region_code: "AF",
    country_code: "BW",
  },
  {
    sales_region_code: "EMEI",
    country_code: "BV",
  },
  {
    sales_region_code: "LSA",
    country_code: "BR",
  },
  {
    sales_region_code: "NA",
    country_code: "BZ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IO",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "SB",
  },
  {
    sales_region_code: "NA",
    country_code: "VG",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "BN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "BG",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MM",
  },
  {
    sales_region_code: "AF",
    country_code: "BI",
  },
  {
    sales_region_code: "EMEI",
    country_code: "BY",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KH",
  },
  {
    sales_region_code: "AF",
    country_code: "CM",
  },
  {
    sales_region_code: "NA",
    country_code: "CA",
  },
  {
    sales_region_code: "AF",
    country_code: "CV",
  },
  {
    sales_region_code: "NA",
    country_code: "KY",
  },
  {
    sales_region_code: "AF",
    country_code: "CF",
  },
  {
    sales_region_code: "LSA",
    country_code: "LK",
  },
  {
    sales_region_code: "AF",
    country_code: "TD",
  },
  {
    sales_region_code: "LSA",
    country_code: "CL",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "CN",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TW",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "CX",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "CC",
  },
  {
    sales_region_code: "LSA",
    country_code: "CO",
  },
  {
    sales_region_code: "AF",
    country_code: "KM",
  },
  {
    sales_region_code: "AF",
    country_code: "YT",
  },
  {
    sales_region_code: "AF",
    country_code: "CG",
  },
  {
    sales_region_code: "AF",
    country_code: "CD",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "CK",
  },
  {
    sales_region_code: "LSA",
    country_code: "CR",
  },
  {
    sales_region_code: "EMEI",
    country_code: "HR",
  },
  {
    sales_region_code: "NA",
    country_code: "CU",
  },
  {
    sales_region_code: "EMEI",
    country_code: "CY",
  },
  {
    sales_region_code: "EMEI",
    country_code: "CZ",
  },
  {
    sales_region_code: "AF",
    country_code: "BJ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "DK",
  },
  {
    sales_region_code: "NA",
    country_code: "DM",
  },
  {
    sales_region_code: "NA",
    country_code: "DO",
  },
  {
    sales_region_code: "LSA",
    country_code: "EC",
  },
  {
    sales_region_code: "LSA",
    country_code: "SV",
  },
  {
    sales_region_code: "AF",
    country_code: "GQ",
  },
  {
    sales_region_code: "AF",
    country_code: "ET",
  },
  {
    sales_region_code: "AF",
    country_code: "ER",
  },
  {
    sales_region_code: "EMEI",
    country_code: "EE",
  },
  {
    sales_region_code: "EMEI",
    country_code: "FO",
  },
  {
    sales_region_code: "LSA",
    country_code: "FK",
  },
  {
    sales_region_code: "LSA",
    country_code: "GS",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "FJ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "FI",
  },
  {
    sales_region_code: "EMEI",
    country_code: "AX",
  },
  {
    sales_region_code: "EMEI",
    country_code: "FR",
  },
  {
    sales_region_code: "LSA",
    country_code: "GF",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PF",
  },
  {
    sales_region_code: "AF",
    country_code: "TF",
  },
  {
    sales_region_code: "AF",
    country_code: "DJ",
  },
  {
    sales_region_code: "AF",
    country_code: "GA",
  },
  {
    sales_region_code: "EMEI",
    country_code: "GE",
  },
  {
    sales_region_code: "AF",
    country_code: "GM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PS",
  },
  {
    sales_region_code: "EMEI",
    country_code: "DE",
  },
  {
    sales_region_code: "AF",
    country_code: "GH",
  },
  {
    sales_region_code: "EMEI",
    country_code: "GI",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KI",
  },
  {
    sales_region_code: "EMEI",
    country_code: "GR",
  },
  {
    sales_region_code: "NA",
    country_code: "GL",
  },
  {
    sales_region_code: "LSA",
    country_code: "GD",
  },
  {
    sales_region_code: "NA",
    country_code: "GP",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "GU",
  },
  {
    sales_region_code: "LSA",
    country_code: "GT",
  },
  {
    sales_region_code: "AF",
    country_code: "GN",
  },
  {
    sales_region_code: "LSA",
    country_code: "GY",
  },
  {
    sales_region_code: "NA",
    country_code: "HT",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "HM",
  },
  {
    sales_region_code: "EMEI",
    country_code: "VA",
  },
  {
    sales_region_code: "NA",
    country_code: "HN",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "HK",
  },
  {
    sales_region_code: "EMEI",
    country_code: "HU",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IS",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IN",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "ID",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "IR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "IQ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IE",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "IL",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IT",
  },
  {
    sales_region_code: "AF",
    country_code: "CI",
  },
  {
    sales_region_code: "NA",
    country_code: "JM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "JP",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KZ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "JO",
  },
  {
    sales_region_code: "AF",
    country_code: "KE",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KP",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KW",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "KG",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "LA",
  },
  {
    sales_region_code: "EMEI",
    country_code: "LB",
  },
  {
    sales_region_code: "AF",
    country_code: "LS",
  },
  {
    sales_region_code: "EMEI",
    country_code: "LV",
  },
  {
    sales_region_code: "AF",
    country_code: "LR",
  },
  {
    sales_region_code: "AF",
    country_code: "LY",
  },
  {
    sales_region_code: "EMEI",
    country_code: "LI",
  },
  {
    sales_region_code: "EMEI",
    country_code: "LT",
  },
  {
    sales_region_code: "EMEI",
    country_code: "LU",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MO",
  },
  {
    sales_region_code: "AF",
    country_code: "MG",
  },
  {
    sales_region_code: "AF",
    country_code: "MW",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MY",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MV",
  },
  {
    sales_region_code: "AF",
    country_code: "ML",
  },
  {
    sales_region_code: "EMEI",
    country_code: "MT",
  },
  {
    sales_region_code: "NA",
    country_code: "MQ",
  },
  {
    sales_region_code: "AF",
    country_code: "MR",
  },
  {
    sales_region_code: "AF",
    country_code: "MU",
  },
  {
    sales_region_code: "NA",
    country_code: "MX",
  },
  {
    sales_region_code: "EMEI",
    country_code: "MC",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "MD",
  },
  {
    sales_region_code: "EMEI",
    country_code: "ME",
  },
  {
    sales_region_code: "NA",
    country_code: "MS",
  },
  {
    sales_region_code: "AF",
    country_code: "MA",
  },
  {
    sales_region_code: "AF",
    country_code: "MZ",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "OM",
  },
  {
    sales_region_code: "AF",
    country_code: "NA",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NP",
  },
  {
    sales_region_code: "EMEI",
    country_code: "NL",
  },
  {
    sales_region_code: "LSA",
    country_code: "AN",
  },
  {
    sales_region_code: "NA",
    country_code: "CW",
  },
  {
    sales_region_code: "NA",
    country_code: "AW",
  },
  {
    sales_region_code: "NA",
    country_code: "SX",
  },
  {
    sales_region_code: "NA",
    country_code: "BQ",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NC",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "VU",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NZ",
  },
  {
    sales_region_code: "LSA",
    country_code: "NI",
  },
  {
    sales_region_code: "AF",
    country_code: "NE",
  },
  {
    sales_region_code: "AF",
    country_code: "NG",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NU",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "NF",
  },
  {
    sales_region_code: "EMEI",
    country_code: "NO",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MP",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "UM",
  },
  {
    sales_region_code: "NA",
    country_code: "UM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "FM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "MH",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PW",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PK",
  },
  {
    sales_region_code: "LSA",
    country_code: "PA",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PG",
  },
  {
    sales_region_code: "LSA",
    country_code: "PY",
  },
  {
    sales_region_code: "LSA",
    country_code: "PE",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PH",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "PN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "PL",
  },
  {
    sales_region_code: "EMEI",
    country_code: "PT",
  },
  {
    sales_region_code: "AF",
    country_code: "GW",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TL",
  },
  {
    sales_region_code: "NA",
    country_code: "PR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "QA",
  },
  {
    sales_region_code: "AF",
    country_code: "RE",
  },
  {
    sales_region_code: "EMEI",
    country_code: "RO",
  },
  {
    sales_region_code: "EMEI",
    country_code: "RU",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "RU",
  },
  {
    sales_region_code: "AF",
    country_code: "RW",
  },
  {
    sales_region_code: "NA",
    country_code: "BL",
  },
  {
    sales_region_code: "AF",
    country_code: "SH",
  },
  {
    sales_region_code: "NA",
    country_code: "KN",
  },
  {
    sales_region_code: "NA",
    country_code: "AI",
  },
  {
    sales_region_code: "NA",
    country_code: "LC",
  },
  {
    sales_region_code: "NA",
    country_code: "MF",
  },
  {
    sales_region_code: "LSA",
    country_code: "PM",
  },
  {
    sales_region_code: "NA",
    country_code: "VC",
  },
  {
    sales_region_code: "EMEI",
    country_code: "SM",
  },
  {
    sales_region_code: "AF",
    country_code: "ST",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "LSA",
  },
  {
    sales_region_code: "AF",
    country_code: "SN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "RS",
  },
  {
    sales_region_code: "AF",
    country_code: "SC",
  },
  {
    sales_region_code: "AF",
    country_code: "SL",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "SG",
  },
  {
    sales_region_code: "EMEI",
    country_code: "SK",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "VN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "SI",
  },
  {
    sales_region_code: "AF",
    country_code: "SO",
  },
  {
    sales_region_code: "AF",
    country_code: "ZA",
  },
  {
    sales_region_code: "AF",
    country_code: "ZW",
  },
  {
    sales_region_code: "EMEI",
    country_code: "ES",
  },
  {
    sales_region_code: "AF",
    country_code: "SS",
  },
  {
    sales_region_code: "AF",
    country_code: "EH",
  },
  {
    sales_region_code: "AF",
    country_code: "SD",
  },
  {
    sales_region_code: "LSA",
    country_code: "SR",
  },
  {
    sales_region_code: "EMEI",
    country_code: "SJ",
  },
  {
    sales_region_code: "AF",
    country_code: "SZ",
  },
  {
    sales_region_code: "EMEI",
    country_code: "SE",
  },
  {
    sales_region_code: "EMEI",
    country_code: "CH",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "SY",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TJ",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TH",
  },
  {
    sales_region_code: "AF",
    country_code: "TG",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TK",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TO",
  },
  {
    sales_region_code: "NA",
    country_code: "TT",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "AE",
  },
  {
    sales_region_code: "AF",
    country_code: "TN",
  },
  {
    sales_region_code: "EMEI",
    country_code: "TR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TR",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TM",
  },
  {
    sales_region_code: "NA",
    country_code: "TC",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "TV",
  },
  {
    sales_region_code: "AF",
    country_code: "UG",
  },
  {
    sales_region_code: "EMEI",
    country_code: "UA",
  },
  {
    sales_region_code: "EMEI",
    country_code: "MK",
  },
  {
    sales_region_code: "AF",
    country_code: "EG",
  },
  {
    sales_region_code: "EMEI",
    country_code: "GB",
  },
  {
    sales_region_code: "EMEI",
    country_code: "GG",
  },
  {
    sales_region_code: "EMEI",
    country_code: "JE",
  },
  {
    sales_region_code: "EMEI",
    country_code: "IM",
  },
  {
    sales_region_code: "AF",
    country_code: "TZ",
  },
  {
    sales_region_code: "NA",
    country_code: "US",
  },
  {
    sales_region_code: "LSA",
    country_code: "VI",
  },
  {
    sales_region_code: "AF",
    country_code: "BF",
  },
  {
    sales_region_code: "LSA",
    country_code: "UY",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "UZ",
  },
  {
    sales_region_code: "NA",
    country_code: "VE",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "WF",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "WS",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "YE",
  },
  {
    sales_region_code: "AF",
    country_code: "ZM",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "XX",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "XE",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "XD",
  },
  {
    sales_region_code: "AS_OC",
    country_code: "XS",
  },
]
const contacts = [
  {
    salesArea: "AFRICA",
    subProductGroup: "AGITAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "AGITAN® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "CERETAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "DEE FO®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "EDAPLAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "FENTAK®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "FOAM BAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "FOAMTROL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "LEUKONÖL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "LUBA-print®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "LUBARIT®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "LUBRANIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "MAGRABAR®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "METOLAT®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "METOLAT® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "OMBRELUB",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "SÜDRANOL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "STABI",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "TAFIGEL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "UMBRELLIT",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "WÜKONIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "WÜKOSEAL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "AFRICA",
    subProductGroup: "ZINPLEX",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "AGITAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "AGITAN® P",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "CERETAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "DEE FO®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "EDAPLAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "FENTAK®",
    mail: "mysales@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "FOAM BAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "FOAMTROL",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "LEUKONÖL",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "LUBA-print®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "LUBARIT®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "LUBRANIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "MAGRABAR®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "METOLAT®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "METOLAT® P",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "OMBRELUB",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "SÜDRANOL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "STABI",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "TAFIGEL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "UMBRELLIT",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "WÜKONIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "WÜKOSEAL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "ASIA_OCEANIA",
    subProductGroup: "ZINPLEX",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "AGITAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "AGITAN® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "CERETAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "DEE FO®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "EDAPLAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "FENTAK®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "FOAM BAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "FOAMTROL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "LEUKONÖL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "LUBA-print®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "LUBARIT®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "LUBRANIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "MAGRABAR®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "METOLAT®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "METOLAT® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "OMBRELUB",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "SÜDRANOL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "STABI",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "TAFIGEL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "UMBRELLIT",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "WÜKONIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "WÜKOSEAL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "EUROPE_MIDDLE-EAST_INDIA",
    subProductGroup: "ZINPLEX",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "AGITAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "AGITAN® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "CERETAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "DEE FO®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "EDAPLAN®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "FENTAK®",
    mail: "CustomerService@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "FOAM BAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "FOAMTROL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "LEUKONÖL",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "LUBA-print®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "LUBARIT®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "LUBRANIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "MAGRABAR®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "METOLAT®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "METOLAT® P",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "OMBRELUB",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "SÜDRANOL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "STABI",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "TAFIGEL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "UMBRELLIT",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "WÜKONIL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "WÜKOSEAL®",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "LATIN-SOUTH_AMERICA",
    subProductGroup: "ZINPLEX",
    mail: "salessupport@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "AGITAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "AGITAN® P",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "CERETAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "DEE FO®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "EDAPLAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "FENTAK®",
    mail: "CustomerService@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "FOAM BAN®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "FOAMTROL",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "LEUKONÖL",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "LUBA-print®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "LUBARIT®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "LUBRANIL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "MAGRABAR®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "METOLAT®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "METOLAT® P",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "OMBRELUB",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "SÜDRANOL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "STABI",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "TAFIGEL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "UMBRELLIT",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "WÜKONIL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "WÜKOSEAL®",
    mail: "mna_info@munzing.com",
  },
  {
    salesArea: "NORTH_AMERICA",
    subProductGroup: "ZINPLEX",
    mail: "mna_info@munzing.com",
  },
]

const getSalesAreaForCountry = (countryCode) => {
  const region = regions.find((region) => region.country_code === countryCode)

  if (region) {
    switch (region.sales_region_code) {
      case "AF":
        return "AFRICA"
      case "AS_OC":
        return "ASIA_OCEANIA"
      case "EMEI":
        return "EUROPE_MIDDLE-EAST_INDIA"
      case "NA":
        return "NORTH_AMERICA"
      case "LSA":
        return "LATIN-SOUTH_AMERICA"
    }
  }

  return "OTHER"
}

const prepareStringForCompare = (str) => {
  return (str || "").toLowerCase().replace(/\W/g, "")
}

const getSubProductGroup = (product) => {
  let prefix = ""
  for (const contact of contacts) {
    if (
      prepareStringForCompare(product.name).startsWith(
        prepareStringForCompare(contact.subProductGroup),
      )
    ) {
      if (prefix.length < contact.subProductGroup.length) {
        prefix = contact.subProductGroup
      }
    }
  }
  return prefix
}

const getContactMail = (product, salesArea) => {
  const subProductGroup = getSubProductGroup(product)
  return (
    contacts.find(
      (contact) =>
        contact.subProductGroup === subProductGroup &&
        contact.salesArea === salesArea,
    ) || { mail: "" }
  ).mail
}

export { getSalesAreaForCountry, getContactMail }
