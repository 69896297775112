import { graphql, useStaticQuery } from "gatsby"

const useProductIds = () => {
  return useStaticQuery(graphql`
    query AllProductIdsQuery {
      allStrapiProduct(filter: { show_on_website: { eq: true } }) {
        edges {
          node {
            strapiId
          }
        }
      }
    }
  `).allStrapiProduct.edges.map(({ node }) => node.strapiId)
}

export default useProductIds
