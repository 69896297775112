import { createStore as reduxCreateStore } from "redux"

import trackCustomEvent from "../util/trackCustomEvent"

const key = "app_state"
const initialState = {
  comparison: [],
  cart: [],
  contactRequestProducts: [],
  filters: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_COMPARISON":
      if (
        state.comparison.findIndex(
          item => item && item.id === action.product.id,
        ) !== -1
      ) {
        return state
      }

      trackCustomEvent({
        category: "product_interaction",
        action: `add_to_comparison`,
        label: `${action.product.name}`,
      })

      return {
        ...state,
        comparison: [...state.comparison, action.product],
      }
    case "REMOVE_COMPARISON":
      const comparisonProduct = state.cart.find(
        item => item && item.id === action.id,
      )

      if (comparisonProduct) {
        trackCustomEvent({
          category: "product_interaction",
          action: `remove_from_comparison`,
          label: `${comparisonProduct.name}`,
        })
      }

      return {
        ...state,
        comparison: state.comparison.filter(
          item => item && item.id !== action.id,
        ),
      }
    case "ADD_CART":
      if (
        state.cart.findIndex(item => item && item.id === action.product.id) !==
        -1
      ) {
        return state
      }

      trackCustomEvent({
        category: "product_interaction",
        action: `add_to_cart`,
        label: `${action.product.name}`,
      })

      return {
        ...state,
        cart: [...state.cart, action.product],
      }
    case "REMOVE_CART":
      const product = state.cart.find(item => item && item.id === action.id)

      if (product) {
        trackCustomEvent({
          category: "product_interaction",
          action: `remove_from_cart`,
          label: `${product.name}`,
        })
      }

      return {
        ...state,
        cart: state.cart.filter(item => item && item.id !== action.id),
      }
    case "REMOVE_ALL_FROM_CART":
      return {
        ...state,
        cart: [],
      }
    case "SET_CONTACT_REQUEST":
      return {
        ...state,
        contactRequestProducts: [...action.products],
      }
    case "DELETE_CONTACT_REQUEST":
      return {
        ...state,
        contactRequestProducts: [],
      }
    case "ADD_FILTER_STATE":
      state.filters[action.key] = action.value
      return {
        ...state,
      }
    default:
      return state
  }
}

const persistReducer = (state, action) => {
  const newState = reducer(state, action)

  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(newState))
  }

  return newState
}

const getInitialStore = () => {
  if (typeof window === "undefined") {
    return initialState
  }

  const storedState = JSON.parse(window.localStorage.getItem(key)) || {}

  storedState.comparison = (storedState.comparison || []).filter(
    p => p.show_in_productlist,
  )

  return Object.assign({}, initialState, storedState)
}

const createStore = () => reduxCreateStore(persistReducer, getInitialStore())
export default createStore
