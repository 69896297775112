import React from 'react';

import './loading-spinner.scss'

const LoadingSpinner = () => {
    return <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" stroke-width="10" stroke-linecap="round" cx="33" cy="33" r="24"></circle>
    </svg>
}

export default LoadingSpinner;