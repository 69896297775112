export default function trackCustomEvent({ action, category, label, value }) {
  // console.log({action, category, label, value});

  if (typeof window.gtag !== "function") return

  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })

  if (typeof window.uaGtag() !== "function") return

  window.uaGtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
