import React from "react"
import { useTranslation, withTranslation } from "react-i18next"

const getCustomT = (t, i18n) => {
  return (key, options = {}) => {
    if (!key) {
      return ""
    }

    let res = t(key, options)

    if (!res) {
      console.warn(
        `Missing translation for lang ${i18n.language} with key "${key}"`,
      )
      res = t(key, { ...options, lng: "en" })
    }
    return res
  }
}

const withCustomTranslation = () => {
  return (WrappedComponent) =>
    withTranslation()(({ t, ...props }) => {
      return <WrappedComponent t={getCustomT(t, props.i18n)} {...props} />
    })
}

const useCustomTranslation = () => {
  const { t, i18n } = useTranslation()
  return { i18n, t: getCustomT(t, i18n) }
}

export {
  withCustomTranslation as withTranslation,
  useCustomTranslation as useTranslation,
}
