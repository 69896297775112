import { GalleryVideoItem } from "../components/gallery/model/gallery-item"
import { Locale } from "../i18n/locale"

export class VideoResource {
  constructor(v) {
    this.description = v.description
    this.video_resource_items = v.video_resource_items || []
  }

  getGalleryItem(language) {
    const defaultLanguage = Locale.getFallbackLocale().getLocaleKey()

    let index = this.video_resource_items.findIndex(
      (item) =>
        Locale.fromPdfFileLanguage(item.language).getLocaleKey() === language,
    )

    // fallback to default language
    if (index === -1) {
      index = this.video_resource_items.findIndex(
        (item) =>
          Locale.fromPdfFileLanguage(item.language).getLocaleKey() ===
          defaultLanguage,
      )
    }

    if (index !== -1) {
      return new GalleryVideoItem(this.video_resource_items[index])
    }
  }
}
