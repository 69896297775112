import { graphql, useStaticQuery } from "gatsby"

import { BrandModel } from "../model/brandModel"

const useBrands = () => {
  const data = useStaticQuery(graphql`
    query BrandsQuery {
      allStrapiBrand {
        edges {
          node {
            id
            url
            hidden
            cover_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, layout: CONSTRAINED)
                }
              }
            }
            strapiId
            description {
              key
            }
            short_description {
              key
            }
            name {
              key
            }
            product_groups {
              id
              value {
                id
                key
              }
            }
            featured_products {
              id
              name
              description {
                key
              }
              availability_regions {
                id
                name {
                  key
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiBrand.edges.map((brand) => {
    return new BrandModel(brand.node)
  })
}

export default useBrands
