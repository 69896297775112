import { Locale } from "./locale"

const setLocaleToLocalStorage = (key) => {
  const locale = new Locale(key)
  window.localStorage.setItem("lang", locale.getLocaleKey())
}
const getLocaleFromLocalStorage = () => {
  return window.localStorage.getItem("lang")
}

export { setLocaleToLocalStorage, getLocaleFromLocalStorage }
