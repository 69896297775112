import { graphql, useStaticQuery } from "gatsby"

import { MarketModel } from "../model/marketModel"

const useMarkets = () => {
  return useStaticQuery(graphql`
    query MarketsQuery {
      allStrapiMarket {
        edges {
          node {
            id
            url
            hidden
            strapiId
            description {
              key
            }
            name {
              key
            }
            cover_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiMarket.edges.map((market) => {
    return new MarketModel(market.node)
  })
}

export default useMarkets
