import { graphql, useStaticQuery } from "gatsby"
import { uniq } from "lodash"

import { removeLocaleFromUri } from "../i18n/removeLocaleFromUri"
import { NavModel } from "../model/navModel"

const useNav = ({ uid, id, path, getRootLevel = false }) => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      allStrapiNavItems {
        edges {
          node {
            id
            strapiId
            name {
              key
            }
            description {
              key
            }
            uid
            url
            order
            parent_nav_item {
              id
            }
            hidden
            page_image {
              image {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                description {
                  key
                }
              }
            }
          }
        }
      }
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  const navItems = data.allStrapiNavItems.edges
    .map(({ node }) => node)
    .sort((curr, prev) => curr.order - prev.order)

  const urls = uniq(
    data.allSitePage.nodes.map((node) => removeLocaleFromUri(node.path)),
  )

  const navModel = new NavModel(
    NavModel.findInArray(navItems, { uid, id, path }),
    navItems,
    urls,
  )

  return getRootLevel ? navModel : navModel.getChild({ uid, id, path })
}

export default useNav
