const urlify = require("urlify").create({
  addEToUmlauts: true,
  toLower: true,
  szToSs: false,
  spaces: "_",
  nonPrintable: "",
  trim: true,
})

export default urlify
