import React, { useRef } from "react"
import MediaQuery from "react-responsive"

import useBrands from "../../../hooks/useBrands"
import useMarkets from "../../../hooks/useMarkets"
import useNav from "../../../hooks/useNav"
import LocalizedLink from "../../../i18n/localized-link"
import { useTranslation } from "../../../i18n/translate"
import SearchIcon from "../../../images/icons/search.svg"
import { NavModel } from "../../../model/navModel"
import {
  getMediaQuery,
  smallPhone,
  smallTablet,
} from "../../../util/media-queries"
import MenuComponent from "../../menu/menu-component"
import "./main-menu.sass"
import MenuItem from "./menu-item"
import MobileMenu from "./mobile-menu"

const MainMenu = () => {
  const containerRef = useRef()
  const { t } = useTranslation()

  const markets = useMarkets()
    .filter((market) => !market.hidden)
    .sort((market, market2) =>
      t(market.name.key).localeCompare(t(market2.name.key)),
    )
  const brands = useBrands()
    .filter((brand) => !brand.hidden)
    .sort((brand, brand2) =>
      t(brand.name.key).localeCompare(t(brand2.name.key)),
    )

  const items = [
    NavModel.createNav({
      translationKey: "MENU-HOME_PAGE_TITLE",
      url: "/",
      partiallyActive: false,
    }),
    NavModel.createNav({
      translationKey: "MENU-MARKETS_PAGE_TITLE",
      url: "/markets/",
      isMegaMenu: true,
      readMoreLinkKey: "MENU-MARKETS_READ_MORE",
      megaMenuDefaultTileTitleKey: "MENU-MARKETS_DEFAULT_TILE_TITLE",
      megaMenuDefaultTileDescriptionKey: "MARKETS-PAGE_DESCRIPTION",
      children: markets.map((market) => {
        return NavModel.createNav({
          translationKey: market.name.key,
          url: market.getProductPageUrl(),
          coverImage: market.cover_image,
        })
      }),
    }),
    NavModel.createNav({
      translationKey: "MENU-PRODUCTS_PAGE_TITLE",
      url: "/products/",
      isMegaMenu: true,
      readMoreLinkKey: "MENU-PRODUCTS_READ_MORE",
      megaMenuDefaultTileTitleKey: "MENU-PRODUCTS_DEFAULT_TILE_TITLE",
      megaMenuDefaultTileDescriptionKey: "BRANDS-PAGE_DESCRIPTION",
      children: brands.map((brand) => {
        return NavModel.createNav({
          translationKey: brand.name.key,
          url: brand.getProductPageUrl(),
          coverImage: brand.cover_image,
        })
      }),
    }),
    NavModel.createNav({
      translationKey: "MENU-CONTACT_PAGE_TITLE",
      url: "/contact/",
    }),
    useNav({ uid: "sustainability" }),
    useNav({ uid: "munzing-group" }),
    useNav({ uid: "downloads" }),
    useNav({ uid: "career" }),
  ].filter((nav) => !nav.hidden)

  return (
    <div className={"_fp-main-menu-wrapper"} ref={containerRef}>
      <div className="_fp-main-menu">
        <MediaQuery {...getMediaQuery(smallPhone, smallTablet)}>
          {(matches) =>
            matches ? (
              <>
                <MobileMenu items={items} />
              </>
            ) : (
              <>
                <LocalizedLink
                  to={"/search/"}
                  activeClassName={"_fp-main-menu__search-button--active"}
                  className="_fp-main-menu__search-button"
                >
                  <SearchIcon />
                </LocalizedLink>
                <MenuComponent
                  containerRef={containerRef}
                  items={items}
                  MenuItemComponent={MenuItem}
                />
              </>
            )
          }
        </MediaQuery>
      </div>
    </div>
  )
}

export default MainMenu
