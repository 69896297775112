import { globalHistory, useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"

import { setLocaleToLocalStorage } from "../../../i18n/languageStorage"
import { Locale } from "../../../i18n/locale"
import localizeLink from "../../../i18n/localize-link"
import { removeLocaleFromUri } from "../../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../../i18n/translate"
import "./language-switcher.sass"

const LanguageSwitcher = () => {
  /// Import stuff ///
  const { i18n } = useTranslation()
  const { location } = globalHistory

  const locale = new Locale(i18n.language)

  const uriWithoutLocale = removeLocaleFromUri(location.pathname)
  const queryParams = useLocation().search

  /// Handle Language Change ///
  const changeLanguage = (newLng) => {
    setLocaleToLocalStorage(newLng)
    i18n.changeLanguage(newLng, () =>
      navigate(localizeLink(uriWithoutLocale, newLng) + queryParams),
    )
  }

  return (
    <div className="_fp-language-switcher">
      <select
        className={"_fp-language-switcher__select"}
        value={locale.getLocaleKey()}
        onBlur={(event) => changeLanguage(event.target.value)}
        onChange={(event) => changeLanguage(event.target.value)}
      >
        <optgroup label="Choose a Language">
          {Locale.getAllSiteLocales()
            .sort((a, b) =>
              a
                .getLocalizedLocaleName()
                .localeCompare(b.getLocalizedLocaleName()),
            )
            .map((l, index) => {
              return (
                <option
                  key={`language-switcher-${index}`}
                  className={"_fp-language-switcher__select__value"}
                  value={l.getLocaleKey()}
                >
                  {l.getLocalizedLocaleName()}
                </option>
              )
            })}
        </optgroup>
      </select>
      <p className="_fp-language-switcher__label">Language</p>
    </div>
  )
}

export default LanguageSwitcher
