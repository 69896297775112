import { removeLocaleFromUri } from "../i18n/removeLocaleFromUri"
import { stripTrailingSlash } from "./strip-trailing-slash"

const isUrlActive = (currentUrl, matchUrl, exactMatch = false) => {
  if (removeLocaleFromUri(matchUrl) === "/" || exactMatch) {
    return (
      stripTrailingSlash(removeLocaleFromUri(matchUrl)) ===
      stripTrailingSlash(removeLocaleFromUri(currentUrl))
    )
  } else {
    return removeLocaleFromUri(currentUrl).startsWith(
      removeLocaleFromUri(matchUrl),
    )
  }
}

export default isUrlActive
