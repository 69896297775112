import i18next from "i18next"

import { siteLanguages } from "../../site-languages.js"

// all possible ['de', 'en', 'ru', 'ja', 'zh', 'ko', 'es', 'fr', pl, pt, it] (for now)
export class Locale {
  // The languages the site is available in
  static _allSiteLocaleKeys = siteLanguages

  // then languages the msds are available in / can be requested in
  static _allMsdsLocaleKeys = ["de", "en", "ru", "ja", "zh", "ko", "es", "fr"]

  constructor(key) {
    this.key = Locale.getCorrectLanguageKey(key)
  }

  static fromPdfFileLanguage(lang) {
    let key
    switch (lang) {
      case "ENGLISH":
        key = "en"
        break
      case "GERMAN":
        key = "de"
        break
      case "FRENCH":
        key = "fr"
        break
      case "RUSSIAN":
        key = "ru"
        break
      case "SPANISH":
        key = "es"
        break
      case "CHINESE":
        key = "zh"
        break
      case "JAPANESE":
        key = "ja"
        break
      case "KOREAN":
        key = "ko"
        break
      default:
        key = "en"
        break
    }

    return new Locale(key)
  }

  getLanguageTranslationKey() {
    switch (this.key) {
      case "zh":
        return "GLOBAL-LANGUAGE-CHINESE"
      case "es":
        return "GLOBAL-LANGUAGE-SPANISH"
      case "de":
        return "GLOBAL-LANGUAGE-GERMAN"
      case "pt":
        return "GLOBAL-LANGUAGE-PORTUGUESE"
      case "fr":
        return "GLOBAL-LANGUAGE-FRENCH"
      case "it":
        return "GLOBAL-LANGUAGE-ITALIAN"
      case "pl":
        return "GLOBAL-LANGUAGE-POLISH"
      case "ja":
        return "GLOBAL-LANGUAGE-JAPANESE"
      case "ru":
        return "GLOBAL-LANGUAGE-RUSSIAN"
      case "ko":
        return "GLOBAL-LANGUAGE-KOREAN"
      default:
        return "GLOBAL-LANGUAGE-ENGLISH"
    }
  }

  getTranslateFunction() {
    return (...props) => {
      return (
        i18next.getFixedT(this.getLocaleKey())(...props) ||
        i18next.getFixedT("en")(...props)
      )
    }
  }

  getEnglishLocaleName() {
    return i18next.t(this.getLanguageTranslationKey(), { lng: "en" })
  }

  getLocalizedLocaleName() {
    return this.getTranslateFunction(i18next)(this.getLanguageTranslationKey())
  }

  getTranslatedLocaleName() {
    return i18next.t(this.getLanguageTranslationKey())
  }

  getLocaleKey() {
    return this.key
  }

  static getSiteLocale(languageKey = "") {
    const normalizedKey = this.getCorrectLanguageKey(languageKey)
    return this._allSiteLocaleKeys.includes(normalizedKey)
      ? new Locale(normalizedKey)
      : this.getFallbackLocale()
  }

  static getFallbackLocale() {
    return new Locale("en")
  }

  static getAllSiteLocales(filterLocales = null) {
    let locales = this._allSiteLocaleKeys
    if (filterLocales) {
      locales = locales.filter((locale) => filterLocales.includes(locale))
    }
    return locales.map((key) => new Locale(key))
  }

  static getAllMsdsLocales() {
    return this._allMsdsLocaleKeys.map((key) => new Locale(key))
  }

  static getSiteLocalesRegex() {
    return this._allSiteLocaleKeys.reduce((a, b) => `${a ? a + "|" : ""}${b}`) // e.g. 'de|en'
  }

  static getCorrectLanguageKey(languageKey = "") {
    const lang = languageKey.toLowerCase()
    if (lang.startsWith("zh")) {
      return "zh"
    } else if (lang.startsWith("de")) {
      return "de"
    } else if (lang.startsWith("es")) {
      return "es"
    } else if (lang.startsWith("pt")) {
      return "pt"
    } else if (lang.startsWith("fr")) {
      return "fr"
    } else if (lang.startsWith("it")) {
      return "it"
    } else if (lang.startsWith("pl")) {
      return "pl"
    } else if (lang.startsWith("ko")) {
      return "ko"
    } else if (lang.startsWith("ja")) {
      return "ja"
    } else if (lang.startsWith("ru")) {
      return "ru"
    } else {
      return "en"
    }
  }
}
