import * as Sentry from "@sentry/browser"
import { CaptureConsole } from "@sentry/integrations"
import { BrowserTracing } from "@sentry/tracing"

import wrapWithProvider from "./wrap-with-provider"

if (typeof window !== "undefined") {
  window["startSentry"] = () => {
    Sentry.init({
      dsn: "https://ceb40b13886c44a4af0000e9761d4e27@o4504140293079040.ingest.sentry.io/4504140307431424",
      environment: process.env.GATSBY_ENV || "develop",
      debug: true,
      integrations: [
        new BrowserTracing(),
        new CaptureConsole({
          levels: ["error"],
        }),
      ],
      tracesSampleRate: 0.01,
      sampleRate: 0.2,
      enabled: true,
      release: process.env.GATSBY_COMMIT || "develop",
      // beforeSend(event) {
      //   // Modify the event here
      //   if (event.user) {
      //     // Don't send user's email address
      //     delete event.user.email
      //   }
      //   return event
      // },
      // ...
    })
  }
}

export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (typeof gtag !== `function`) {
    return null
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag("event", "page_view", {
      page_title: location.title,
      page_location: location.href,
      page_path: pagePath,
    })
  }

  setTimeout(sendPageView, 0)

  return null
}
