import { globalHistory } from "@reach/router"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { withTranslation } from "../../../i18n/translate"
import "./mega-menu.sass"

const menusort = (items, columns) => {
  const rows = Math.ceil(items.length / columns)

  const newItems = []

  for (let i = 0; i < rows; i++) {
    for (let j = i; j < rows * columns; j += rows) {
      newItems.push(items[j])
    }
  }

  return newItems
}

class MegaMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeItem: undefined,
      closed: false,
    }
  }

  componentDidMount() {
    this.removeHistoryListener = globalHistory.listen((action) => {
      if (action.action === "PUSH") {
        this.setState({ closed: true })
        setTimeout(() => {
          this.setState({ closed: false })
        }, 100)
      }
    })
  }

  componentWillUnmount() {
    this.removeHistoryListener()
  }

  render() {
    const { t, items, navItem } = this.props
    const { activeItem } = this.state
    const setActiveItem = (activeItem) => this.setState({ activeItem })

    return (
      <div
        className={classNames("_fp-mega-menu-container", {
          "_fp-mega-menu-container--close": this.state.closed,
        })}
      >
        <div className="_fp-global-container">
          <div className={"_fp-mega-menu"}>
            <div className="_fp-mega-menu__header">
              <div className="_fp-mega-menu__header__title _fp-heading-1">
                {t(navItem.translationKey) || navItem.translatedString}
              </div>
              <div className="_fp-mega-menu__header__actions">
                <LocalizedLink
                  tabIndex={-1}
                  className={"_fp-cta-link _fp-cta-link--large"}
                  to={navItem.url}
                >
                  {t(navItem.readMoreLinkKey)}
                </LocalizedLink>
              </div>
            </div>
            <div className="_fp-mega-menu__image-wrapper">
              <div className="_fp-mega-menu__content">
                {menusort(
                  items.sort((a, b) =>
                    (
                      t(a.translationKey) ||
                      a.translatedString ||
                      ""
                    ).localeCompare(
                      t(b.translationKey) || b.translatedString || "",
                    ),
                  ),
                  3,
                ).map((item, index) => {
                  if (!item) {
                    return (
                      <div
                        className={"_fp-mega-menu__content__item"}
                        key={`mega-menu-item_` + index}
                      ></div>
                    )
                  }
                  return (
                    <LocalizedLink
                      key={`mega-menu-item_` + index}
                      tabIndex={-1}
                      className={"_fp-mega-menu__content__item"}
                      activeClassName={"_fp-mega-menu__content__item--active"}
                      partiallyActive={item.partiallyActive}
                      to={item.url}
                      matchUrl={item.srcUrl}
                      onMouseEnter={() => setActiveItem(item)}
                      onMouseLeave={() => setActiveItem(undefined)}
                    >
                      {t(item.translationKey) || item.translatedString}
                    </LocalizedLink>
                  )
                })}
              </div>
              <div
                className={classNames("_fp-mega-menu__image-wrapper__image", {
                  "_fp-mega-menu__image-wrapper__image--empty": !activeItem,
                })}
              >
                {activeItem && (
                  <GatsbyImage
                    image={
                      activeItem.coverImage.childImageSharp?.gatsbyImageData
                    }
                    alt={
                      t(activeItem.translationKey) ||
                      activeItem.translatedString
                    }
                  ></GatsbyImage>
                )}
                <div className={"_fp-mega-menu__image-wrapper__image__title"}>
                  {activeItem && (
                    <span className={"_fp-heading-1"}>
                      {t(activeItem?.translationKey) ||
                        activeItem?.translatedString}
                    </span>
                  )}
                  {!activeItem && (
                    <>
                      <div>
                        <span className={"_fp-heading-1"}>
                          {t(navItem.megaMenuDefaultTileTitleKey)}
                        </span>
                        <p>{t(navItem.megaMenuDefaultTileDescriptionKey)}</p>
                      </div>
                      <LocalizedLink to={navItem.url} className="_fp-cta-link">
                        {t(navItem.readMoreLinkKey)}
                      </LocalizedLink>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(MegaMenu)
