import { compact, concat, remove, uniq } from "lodash"
import React from "react"
import { Helmet } from "react-helmet"

const defaultContext = {
  setBodyClass: () => {},
  removeBodyClass: () => {},
  setBodyAttributes: () => {},
}

export const BodyAttributesContext = React.createContext(defaultContext)

export class BodyAttributesContextProvider extends React.Component {
  constructor(props) {
    super(props)
    this.classList = []
    this.state = {
      setBodyClass: (className, shown = true) =>
        this.setBodyClass(className, shown),
      removeBodyClass: (className) => this.removeBodyClass(className),
      setBodyAttributes: (attributes) => this.setAttributes(attributes),
      attributes: {},
    }
  }

  setBodyClass(className, shown = true) {
    if (!shown) {
      this.removeBodyClass(className)
      return
    }
    this.classList = uniq(concat(this.classList, compact(className.split(" "))))
    this.setAttributes({})
  }

  removeBodyClass(className) {
    remove(this.classList, (item) => item === className)
    this.setAttributes({})
  }

  getClassAsString() {
    return this.classList.reduce((a, b) => a + " " + b, "")
  }

  setAttributes(attributes) {
    this.setState({
      attributes: {
        ...this.state.attributes,
        ...attributes,
        class: this.getClassAsString(),
      },
    })
  }

  render() {
    return (
      <BodyAttributesContext.Provider value={this.state}>
        <Helmet bodyAttributes={this.state.attributes} />
        {this.props.children}
      </BodyAttributesContext.Provider>
    )
  }
}

export class SetBodyClass extends React.Component {
  componentDidMount() {
    this.names = this.props.className.split(" ")
    for (const name of this.names) {
      this.context.setBodyClass(name)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newNames = this.props.className.split(" ")

    for (const name of this.names) {
      if (!newNames.includes(name)) {
        this.context.removeBodyClass(name)
      }
    }
    for (const newName of newNames) {
      if (!this.names.includes(newName)) {
        this.context.setBodyClass(newName)
      }
    }

    this.names = newNames
  }

  componentWillUnmount() {
    for (const name of this.names) {
      this.context.removeBodyClass(name)
    }
  }

  render() {
    return <></>
  }
}

SetBodyClass.contextType = BodyAttributesContext
