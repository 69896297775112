module.exports = [{
      plugin: require('../node_modules/gatsby-source-softgarden/gatsby-browser.js'),
      options: {"plugins":[],"clientId":"65ec0d33-e479-487b-bd61-fb8991220582","channel":"78358_extern","debug":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/src/app/src/layout/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
