import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import withProductIds from "../../hooks/withProductIds"
import { withTranslation } from "../../i18n/translate"
import EmptyCartIcon from "../../images/icons/cart_empty.svg"
import FullCartIcon from "../../images/icons/cart_full.svg"
import Button from "../button/button"

class GoToProductCartButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: 0, animation: false }
  }

  componentDidMount() {
    this.updateCheckValue(true)
  }

  componentDidUpdate(prevProps) {
    this.updateCheckValue()
  }

  updateCheckValue(initial = false) {
    const newCartLength = this.props.cart.filter((p) => {
      return this.props.productIds.includes(p.id)
    }).length
    if (this.state.value !== newCartLength) {
      const newState = { value: newCartLength }

      if (this.state.value < newCartLength && !initial) {
        // Trigger animation
        newState.animation = true
        setTimeout(() => {
          this.setState({ animation: false })
        }, 250)
      }

      this.setState(newState)
    }
  }

  render() {
    const { t } = this.props
    return (
      <Button
        buttonLinkPath={"/order-product-sample/"}
        buttonSvgIconComponent={
          this.state.value > 0 ? <FullCartIcon /> : <EmptyCartIcon />
        }
        buttonStyle={
          this.state.animation
            ? "green"
            : this.props.showBg
            ? "green-soft"
            : "green-ghost"
        }
        buttonLabel={t(
          "PRODUCTS-SAMPLE_REQUEST-REQUEST_PRODUCT_SAMPLES_COUNT",
          {
            postProcess: "interval",
            count: this.state.value,
          },
        )}
      />
    )
  }
}

const mapStateToProps = ({ cart }) => {
  return {
    cart,
  }
}

GoToProductCartButton.propTypes = {
  showBg: PropTypes.bool,
}

export default withTranslation()(
  connect(mapStateToProps, {})(withProductIds(GoToProductCartButton)),
)
