import { globalHistory } from "@reach/router"
import React from "react"

import useIsActive from "../../../hooks/useIsActive"
import localizeLink from "../../../i18n/localize-link"
import LocalizedLink from "../../../i18n/localized-link"
import { removeLocaleFromUri } from "../../../i18n/removeLocaleFromUri"
import { useTranslation, withTranslation } from "../../../i18n/translate"
import ArrowRightSmallIcon from "../../../images/icons/arrow_right_small.svg"
import SearchIcon from "../../../images/icons/search.svg"
import { NavModel } from "../../../model/navModel"
import MenuOverlay from "../../menu-overlay/menu-overlay"
import MenuButton from "../../menu/menu-button"
import GoToProductCartButton from "../go-to-product-cart-button"
import GoToProductComparisonButton from "../go-to-product-comparison-button"
import LanguageSwitcher from "../language-switcher/language-switcher"
import "./mobile-menu.sass"

const MobileMenuChildButton = ({ children, onClick, matchUrl }) => {
  const { i18n } = useTranslation()
  const locale = i18n.language
  const matchPath = localizeLink(matchUrl, locale)
  const isActive = useIsActive(matchPath)

  return (
    <button
      onClick={onClick}
      className={
        "_fp-mobile-menu__link _fp-mobile-menu__item__child-button " +
        (isActive ? "_fp-mobile-menu__link--active" : "")
      }
    >
      {children}
      <ArrowRightSmallIcon />
    </button>
  )
}

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.menuRoot = new NavModel({
      translationKey: "Main Menu",
      children: this.props.items,
    })
    this.defaultItemsPath = [this.menuRoot]

    this.state = {
      active: false,
      items: this.props.items,
      itemsPath: this.defaultItemsPath,
    }
  }

  componentDidMount() {
    this.setPathName(globalHistory.location.pathname)
    this.removeHistoryListener = globalHistory.listen((action) => {
      this.setPathName(action.location.pathname)
      if (action.action === "PUSH") {
        this.setMenuState(false)
      }
    })
  }

  componentWillUnmount() {
    this.removeHistoryListener()
  }

  setPathName(pathName) {
    this.currentPath = pathName

    const item = this.menuRoot.getParentOf({
      partialPath: removeLocaleFromUri(pathName),
    })

    if (!item) {
      const newState = {}

      newState.itemsPath = this.defaultItemsPath
      newState.items = this.props.items
      newState.parent = undefined

      this.setState(newState)
    } else {
      this.selectItem(item)
    }
  }

  setMenuState(active) {
    const newState = {
      active,
    }

    this.setState(newState)
  }

  selectItem(item) {
    const index = this.state.itemsPath.findIndex((el) => el === item)
    let itemsPath, newItem

    if (index !== -1) {
      itemsPath = this.state.itemsPath.slice(0, index + 1)
      newItem = itemsPath[index]
    } else {
      itemsPath = [...this.state.itemsPath, item]
      newItem = item
    }

    this.setState({
      itemsPath,
      items: newItem.children,
      parent: newItem,
    })
  }

  render() {
    const setActive = (s) => this.setMenuState(s)
    const { active, items, itemsPath } = this.state
    const { t } = this.props

    const parentItem = itemsPath[itemsPath.length - 1]
    const secondLastItem = itemsPath[itemsPath.length - 2] // get second last item (for back button behavior)

    return (
      <div className={"_fp-mobile-menu"}>
        <LocalizedLink
          to={"/search/"}
          activeClassName={"_fp-mobile-menu__search-button--active"}
          className="_fp-mobile-menu__search-button"
        >
          <SearchIcon />
        </LocalizedLink>

        <MenuButton state={active} setState={setActive} />

        <MenuOverlay state={active} setState={setActive}>
          {itemsPath.length > 1 && (
            <div className="_fp-mobile-menu__back">
              <button
                onClick={() => this.selectItem(secondLastItem)}
                className={"_fp-mobile-menu__back__button"}
              >
                {t("GLOBAL-GO_BACK")}
              </button>
            </div>
          )}

          {/* the overview menu item is only shown if its page exists */}
          {this.state.parent?.url &&
            !/\/?undefined\/?/.test(this.state.parent?.url) &&
            this.state.parent?.url === this.state.parent?.srcUrl &&
            !parentItem.isMegaMenu && (
              <div className="_fp-mobile-menu__item _fp-mobile-menu__item--is-header">
                <LocalizedLink
                  className={"_fp-mobile-menu__link"}
                  activeClassName={"_fp-mobile-menu__link--active"}
                  to={this.state.parent?.url}
                  matchUrl={this.state.parent?.srcUrl}
                  exactMatch={true}
                >
                  {t("GLOBAL-OVERVIEW")}
                </LocalizedLink>
              </div>
            )}

          {/* If isMegaMenu set the more about link */}
          {itemsPath.length > 0 && parentItem.isMegaMenu && (
            <div
              className={
                "_fp-mobile-menu__item _fp-mobile-menu__item--is-header"
              }
            >
              <LocalizedLink
                className={"_fp-mobile-menu__link"}
                activeClassName={"_fp-mobile-menu__link--active"}
                partiallyActive={parentItem.partiallyActive}
                to={parentItem.url}
                matchUrl={parentItem.srcUrl}
              >
                {t(parentItem.readMoreLinkKey)}
              </LocalizedLink>
            </div>
          )}

          {items.map((item, index) => {
            const { partiallyActive = true } = item
            return (
              <div
                key={`mobile-menu-item_${index}`}
                className={"_fp-mobile-menu__item"}
              >
                {item.children && item.children.length > 0 ? (
                  <MobileMenuChildButton
                    onClick={() => this.selectItem(item)}
                    matchUrl={item.srcUrl}
                  >
                    <span>
                      {t(item.translationKey) || item.translatedString}
                    </span>
                  </MobileMenuChildButton>
                ) : (
                  <LocalizedLink
                    className={"_fp-mobile-menu__link"}
                    activeClassName={"_fp-mobile-menu__link--active"}
                    partiallyActive={partiallyActive}
                    to={item.url}
                    matchUrl={item.srcUrl}
                  >
                    {t(item.translationKey) || item.translatedString}
                  </LocalizedLink>
                )}
              </div>
            )
          })}

          <div className="_fp-mobile-menu__actions">
            <GoToProductComparisonButton showBg />
            <GoToProductCartButton showBg />
            <LanguageSwitcher />
          </div>
        </MenuOverlay>
      </div>
    )
  }
}

export default withTranslation()(MobileMenu)
