import React from "react"

import useProductIds from "./useProductIds"

const withProductIds = (ChildComponent) => {
  return (props) => {
    const productIds = useProductIds()
    return <ChildComponent productIds={productIds} {...props} />
  }
}

export default withProductIds
