import { useLocation } from "@reach/router"

import isUrlActive from "../util/isUrlActive"

const useIsActive = (matchPath = "", exactMatch = false) => {
  const location = useLocation()

  return isUrlActive(location.pathname, matchPath, exactMatch)
}

export default useIsActive
