import { toUrl } from "../util/urls"

export class MarketModel {
  constructor(marketLike) {
    this.id = +marketLike.strapiId || +marketLike.id
    this.name = marketLike.name
    this.hidden = marketLike.hidden || false
    this.url = marketLike.url
    this.description = marketLike.description
    this.cover_image = marketLike.cover_image
      ? marketLike.cover_image.localFile
      : {}
  }

  getProductPageUrl() {
    return toUrl(`/products/market/${this.url}`)
  }

  getDetailPageUrl() {
    return toUrl(`/markets/${this.url}`)
  }
}
