import { stripTrailingSlash } from "../util/strip-trailing-slash"
import { toUrl } from "../util/urls"

export class NavModel {
  constructor(
    n = {},
    navItems = [],
    existingUrls = undefined,
    addParent = true,
    urlPrefix = "",
    level = 0,
  ) {
    this.id = n.strapiId || n.id
    this.name = n.name || { key: n.translationKey || "" } /// {key: '14521341', en: 'Sustainability', de: 'Nachhaltigkeit'}
    this.description = n.description
    this.uid = n.uid
    this.translationKey = this.name.key /// '1234354
    this.children = n.children || [] /// Nav Model
    this.url = toUrl(urlPrefix + "/" + n.url) /// URL Prefix for recursion purposes
    this.srcUrl = toUrl(urlPrefix + "/" + n.url)
    this.page_image = n.page_image
    this.level = level
    this.hidden = n.hidden || false

    // Mega Menu
    this.isMegaMenu = n.isMegaMenu || false
    this.coverImage = n.coverImage
    this.readMoreLinkKey = n.readMoreLinkKey
    this.megaMenuDefaultTileTitleKey = n.megaMenuDefaultTileTitleKey
    this.megaMenuDefaultTileDescriptionKey = n.megaMenuDefaultTileDescriptionKey

    // if addParent is set and the current item has a parent, return its parent instead of the current item.
    if (addParent && n.parent_nav_item && n.parent_nav_item.id) {
      return new NavModel(
        NavModel.findInArray(navItems, { id: n.parent_nav_item.id }),
        navItems,
        existingUrls,
        true,
      )
    }

    // Create Children for current NavItem
    navItems.forEach((item) => {
      // If any navItem has a parent with the id of the current navItem, set that navItem as a child.
      if (
        item.parent_nav_item &&
        item.parent_nav_item.id &&
        item.parent_nav_item.id === this.id &&
        !item.hidden
      ) {
        const child = new NavModel(
          item,
          navItems,
          existingUrls,
          false,
          this.url,
          level + 1,
        )
        if (!child.hidden) {
          this.children.push(child)
        }
      }
    })

    if (existingUrls) {
      if (
        !existingUrls.includes(stripTrailingSlash(this.url) + "/") &&
        this.children.length > 0
      ) {
        this.url = this.children[0].url
      } else if (
        existingUrls.findIndex((url) => url.startsWith(this.url)) === -1
      ) {
        // this page does not exist and does not have any children
        // console.error('The url "' + this.url + '" does not exist.')
        this.hidden = true
      }
    }
  }

  matches({ uid, id, path, partialPath }) {
    if (typeof uid !== "undefined") {
      return this.uid === uid
    } else if (typeof id !== "undefined") {
      return this.id === id
    } else if (typeof path !== "undefined") {
      return stripTrailingSlash(this.srcUrl) === stripTrailingSlash(path)
    } else if (typeof partialPath !== "undefined") {
      return stripTrailingSlash(partialPath).startsWith(
        stripTrailingSlash(this.srcUrl),
      )
    } else {
      return false
    }
  }

  /**
   * Performs a depth first search on the models children
   *
   * @param selector param for the matches function
   * @returns {NavModel|*|Yoga.YogaNode|undefined}
   */
  getChild(selector) {
    let res = undefined

    if (this.matches(selector)) {
      res = this
    }

    for (const child of this.children) {
      if (child.matches(selector)) {
        res = child
      }
      const c = child.getChild(selector)
      if (c !== undefined) {
        res = c
      }
    }
    return res
  }

  getParentOf(selector) {
    let res = undefined

    for (const child of this.children) {
      if (child.matches(selector)) {
        res = this
      }
      const c = child.getParentOf(selector)
      if (c !== undefined) {
        res = c
      }
    }
    return res
  }

  static findInArray(navItems, { uid, id, path }) {
    return navItems
      .map((n) => n)
      .sort((node1, node2) => node2.url.length - node1.url.length)
      .find((node) => {
        if (typeof uid !== "undefined") {
          return node.uid === uid
        } else if (typeof id !== "undefined") {
          return (node.strapiId || node.id) === id
        } else if (typeof path !== "undefined") {
          return path.startsWith(node.url)
        }
        return false
      })
  }

  static createNav(n) {
    return new NavModel(
      n,
      [],
      [n.url, ...(n.children || []).map((item) => item.url)],
    )
  }
}
