import { toUrl } from "../util/urls"
import { ProductModel } from "./productModel"

export class BrandModel {
  constructor(brandLike) {
    this.id = brandLike.strapiId || +brandLike.id
    this.name = brandLike.name
    this.url = brandLike.url
    this.hidden = brandLike.hidden || false
    this.cover_image = brandLike.cover_image
      ? brandLike.cover_image.localFile
      : {}
    this.description = brandLike.description || {}
    this.short_description = brandLike.short_description || {}
    this.featured_products = (brandLike.featured_products || []).map(
      (product) => new ProductModel(product),
    )
    this.product_groups = brandLike.product_groups || []
  }

  getProductPageUrl(context = {}) {
    let search = ""
    if (context.market) {
      search = `?market=${context.market.id}`
    }
    return toUrl(`/products/brand/${this.url}`) + search
  }

  getDetailPageUrl() {
    return toUrl(`/brands/${this.url}`)
  }

  getProductGroupsString(t) {
    const productGroups = this.product_groups.reduce(
      (accumulator, current, i, array) => {
        return (
          accumulator +
          t(current.name.key) +
          (array.length - 1 === i ? "" : ", ")
        )
      },
      "",
    )
    return productGroups
  }
}
