export const smallPhone = [undefined, 399]
export const phone = [400, 599]
export const smallTablet = [600, 899]
export const tablet = [900, 1199]
export const smallDesktop = [1200, 1599]
export const desktop = [1600, 1949]
export const largeDesktop = [1950, undefined]

export const getMediaQuery = (a, b) => {
  const res = {}

  if (a[0] && b[0]) {
    res.minWidth = Math.min(a[0], b[0])
  }

  if (a[1] && b[1]) {
    res.maxWidth = Math.max(a[1], b[1])
  }

  return res
}
