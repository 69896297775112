import { globalHistory } from "@reach/router"
import classNames from "classnames"
import { navigate } from "gatsby"
import i18next from "i18next"
import React from "react"

import {
  getLocaleFromLocalStorage,
  setLocaleToLocalStorage,
} from "../../../i18n/languageStorage"
import { Locale } from "../../../i18n/locale"
import localizeLink from "../../../i18n/localize-link"
import { removeLocaleFromUri } from "../../../i18n/removeLocaleFromUri"
import { withTranslation } from "../../../i18n/translate"
import CloseIcon from "../../../images/icons/close.svg"
import { BodyAttributesContext } from "../../../util/body-attributes-context"
import "./language-recommendation.sass"

class LanguageRecommendation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languageRecommendationShown: false,
      recommendedLanguage: undefined,
    }
  }

  componentDidMount() {
    const lang = Locale.getSiteLocale(getLocaleFromLocalStorage()).key

    const shown = lang !== i18next.language
    this.setState({
      recommendedLanguage: lang,
      languageRecommendationShown: shown,
    })

    this.context.setBodyClass("_fp-language-recommendation-shown", shown)

    // Automatically hide the banner, when the language is changed to the correct one
    i18next.on("languageChanged", (lng) => {
      if (lng === this.state.recommendedLanguage) {
        this.hideLanguageRecommendation()
      }
    })

    if (i18next.language === undefined) {
      this.hideLanguageRecommendation()
    }
  }

  hideLanguageRecommendation() {
    this.context.setBodyClass("_fp-language-recommendation-shown", false)
    this.setState({ languageRecommendationShown: false })
  }

  useRecommendedLanguage() {
    const lang = this.state.recommendedLanguage
    setLocaleToLocalStorage(lang)
    i18next.changeLanguage(lang, () =>
      navigate(
        localizeLink(
          removeLocaleFromUri(globalHistory.location.pathname),
          lang,
        ),
      ),
    )
  }

  useCurrentLanguage() {
    this.context.setBodyClass("_fp-language-recommendation-shown", false)
    this.setState({
      recommendedLanguage: i18next.language,
      languageRecommendationShown: false,
    })
    setLocaleToLocalStorage(i18next.language)
  }

  render() {
    const { t } = this.props
    const currentLocale = new Locale(i18next.language)
    const recommendedLocale = Locale.getSiteLocale(
      this.state.recommendedLanguage,
    )

    return (
      <>
        <aside
          className={classNames("_fp-language-recommendation", {
            "_fp-language-recommendation--shown":
              this.state.languageRecommendationShown,
          })}
        >
          <div className="_fp-global-container">
            <div className="_fp-language-recommendation__container">
              <div className="_fp-language-recommendation__container__copy">
                <button
                  className={"_fp-link _fp-link--small"}
                  onClick={() => this.useCurrentLanguage()}
                >
                  {t("GLOBAL-LANGUAGE_BANNER-SET_LANGUAGE_AS_DEFAULT", {
                    language: t(currentLocale.getLanguageTranslationKey()),
                  })}
                </button>
              </div>

              <div className="_fp-language-recommendation__container__actions">
                <button
                  className={
                    "_fp-language-recommendation__container__actions__recommended-language-button"
                  }
                  onClick={() => this.useRecommendedLanguage()}
                >
                  <p className="_fp-language-recommendation__container__actions__recommended-language-button__text">
                    {recommendedLocale.getTranslateFunction()(
                      "GLOBAL-LANGUAGE_BANNER-USE_LANGUAGE",
                      { language: recommendedLocale.getLocalizedLocaleName() },
                    )}
                  </p>
                </button>
                <button
                  className={
                    "_fp-language-recommendation__container__actions__close-button"
                  }
                  onClick={() => this.hideLanguageRecommendation()}
                >
                  <div className="_fp-language-recommendation__container__actions__close-button__icon">
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

LanguageRecommendation.contextType = BodyAttributesContext

export default withTranslation()(LanguageRecommendation)
