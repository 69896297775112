import { closest, distance } from "fastest-levenshtein"

import {
  getContactMail,
  getSalesAreaForCountry,
} from "../util/sales-area-mapping"
import urlify from "../util/urlify"
import { toUrl } from "../util/urls"
import { VideoResource } from "./videoResource"

const sigmoid = (x) => {
  return x / (1 + Math.abs(x))
}

export class ProductModel {
  name = ""
  description = ""
  markets = []
  enum_product_parameter_values
  effect_product_parameter_values
  number_product_parameter_values
  range_product_parameter_values
  dual_number_product_parameter_values
  boolean_product_parameter_values
  availability_regions
  sample_size
  additional_downloads

  show_in_productlist = true
  _cache = []

  constructor(productLike) {
    this.id = productLike.strapiId || +productLike.id
    if (typeof productLike.show_in_productlist !== "undefined") {
      this.show_in_productlist = !!productLike.show_in_productlist
    }
    this.name = productLike.name
    this.description = productLike.description || {}
    this.brand = productLike.brand
    this.sample_size = (productLike.brand || {}).sample_size
    this.markets = productLike.markets || []

    this.additional_downloads = productLike.additional_downloads || []

    this.enum_product_parameter_values = (
      productLike.enum_product_parameter_values || []
    )
      .map((item) => {
        return { ...item, value: { ...item.value, _valueId: item.id } }
      })
      // remove static product parameter groups (brand, market, availability)
      .filter((value) => value.product_parameter_group.id >= 0)
    this.effect_product_parameter_values =
      productLike.effect_product_parameter_values || []
    this.number_product_parameter_values =
      productLike.number_product_parameter_values || []
    this.range_product_parameter_values =
      productLike.range_product_parameter_values || []
    this.dual_number_product_parameter_values =
      productLike.dual_number_product_parameter_values || []
    this.boolean_product_parameter_values =
      productLike.boolean_product_parameter_values || []
    this.availability_regions = productLike.availability_regions || []

    this.video_resources = (productLike.video_resources || []).map(
      (v) => new VideoResource(v),
    )

    // Add brand product parameter value
    if (this.brand) {
      this.enum_product_parameter_values.push({
        product_parameter_group: { id: -1 },
        value: {
          key: this.brand.name.key,
          value_en: this.brand.name.value_en,
          id: this.brand.id,
          _valueId: this.brand.id,
        },
        id: this.brand.id,
      })
    }
    // Add markets product parameter value(s)
    if (this.markets.length > 0) {
      this.markets.forEach((market) => {
        this.enum_product_parameter_values.push({
          product_parameter_group: { id: -2 },
          value: {
            key: market.name.key,
            value_en: market.name.value_en,
            id: market.id,
            _valueId: market.id,
          },
          id: market.id,
        })
      })
    }
    // Add availability product parameter value(s)
    for (const availabilityRegion of this.availability_regions) {
      this.enum_product_parameter_values.push({
        product_parameter_group: { id: -3 },
        value: {
          key: availabilityRegion.name.key,
          value_en: availabilityRegion.name.value_en,
          id: availabilityRegion.id,
          _valueId: availabilityRegion.id,
        },
        id: availabilityRegion.id,
      })
    }
  }

  getUrl(context = {}) {
    let search = ""
    if (context.market) {
      search = `?market=${context.market.id}`
    }
    return toUrl(`/products/${urlify(this.name)}`) + search
  }

  getUrlifiedName() {
    return urlify(this.name)
  }

  hasMarket(market) {
    for (let i = 0; i < this.markets.length; i++) {
      if (this.markets[i].url === market) {
        return true
      }
    }
    return false
  }

  hasBrand(brand) {
    return this.brand && this.brand.url === brand
  }

  /**
   * Returns the string value for a param identified by its param group
   * @param group
   * @returns {*}
   */
  getParamValues(group) {
    if (group.type === "ENUM") {
      return this.enum_product_parameter_values.filter(
        (val) =>
          val.product_parameter_group.id === (group._id || group.strapiId),
      )
    } else if (group.type === "EFFECT") {
      return this.effect_product_parameter_values.filter(
        (val) =>
          val.product_parameter_group.id === (group._id || group.strapiId),
      )
    } else if (group.type === "NUMBER") {
      return this.number_product_parameter_values.filter(
        (val) =>
          val.product_parameter_group.id === (group._id || group.strapiId),
      )
    } else if (group.type === "RANGE") {
      return this.range_product_parameter_values.filter(
        (val) =>
          val.product_parameter_group.id === (group._id || group.strapiId),
      )
    } else if (group.type === "DUAL_NUMBER") {
      return this.dual_number_product_parameter_values.filter(
        (val) =>
          val.product_parameter_group.id === (group._id || group.strapiId),
      )
    }
  }

  /**
   * Returns the availability as translated string
   * @param t the translate function to use
   * @returns {string} availability as human readable string
   */
  getAvailabilityString(t) {
    let res = ""

    for (const availabilityRegion of this.availability_regions) {
      res += t(availabilityRegion.name.key) + " · "
    }

    return res.replace(/ · $/, "")
  }

  hasAvailability() {
    return this.availability_regions.length > 0
  }

  prepareParamsForCompare(useGroups = []) {
    const values = {}
    const stringValues = {}

    const useAllGroups = useGroups.length === 0

    if (useAllGroups) {
      this.markets.forEach((market) => {
        values[`market_${market.id}`] = 0.5
      })
    }

    if (useAllGroups && this.brand) {
      values[`brand_${this.brand.id}`] = 0.5
    }

    stringValues["name"] = [this.name]

    this.enum_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        values[
          `enum_${value.product_parameter_group.id}_${value.value.key}`
        ] = 0.5

        if (!stringValues[`enum_${value.product_parameter_group.id}`]) {
          stringValues[`enum_${value.product_parameter_group.id}`] = []
        }
        stringValues[`enum_${value.product_parameter_group.id}`].push(
          value.value.value_en,
        )
      })
    this.effect_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        if (!values[`effect_${value.product_parameter_group.id}`]) {
          values[`effect_${value.product_parameter_group.id}`] = 0
        }
        values[`effect_${value.product_parameter_group.id}`] += 0.1
        values[
          `effect_${value.product_parameter_group.id}_${value.value}`
        ] = 0.3
      })
    this.number_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        values[`number_${value.product_parameter_group.id}`] = sigmoid(
          value.value,
        )
      })
    this.range_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        values[`range_${value.product_parameter_group.id}`] = sigmoid(
          (value.firstValue + value.lastValue) / 2,
        )
      })
    this.dual_number_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        values[`dual_number_${value.product_parameter_group.id}`] = sigmoid(
          (value.value1 + value.value2) / 2,
        )
      })
    this.boolean_product_parameter_values
      .filter((value) => {
        return (
          useAllGroups || useGroups.includes(value.product_parameter_group.id)
        )
      })
      .forEach((value) => {
        values[`boolean_${value.product_parameter_group.id}`] = 0.5
      })

    return [values, stringValues]
  }

  distanceTo(product, useGroups = []) {
    if (this._cache[product.name]) {
      return this._cache[product.name]
    }

    const [values1, stringValues1] = this.prepareParamsForCompare(useGroups)
    const [values2, stringValues2] = product.prepareParamsForCompare(useGroups)

    const keys = new Set(Object.keys(values1))
    Object.keys(values2).forEach((key) => keys.add(key))

    const stringKeys = new Set(Object.keys(stringValues1))
    Object.keys(stringValues2).forEach((key) => stringKeys.add(key))

    let dist = 0
    for (let key of keys) {
      dist += Math.pow((values1[key] || 0) - (values2[key] || 2), 2)
    }

    let stringDist = 0
    for (let key of stringKeys) {
      const distances = [10]
      for (let string1 of stringValues1[key] || [""]) {
        distances.push(
          distance(string1, closest(string1, stringValues2[key] || [""])),
        )
      }
      stringDist += Math.min(...distances) * 10
    }

    const res = Math.sqrt(dist) + Math.sqrt(stringDist)

    this._cache[product.name] = res

    return res
  }

  static getComparator =
    (product, filters = []) =>
    (a, b) => {
      const distanceA = product.distanceTo(a, filters)
      const distanceB = product.distanceTo(b, filters)

      return distanceA - distanceB
    }

  static prepareForWebforms(products, country = {}) {
    const salesArea = getSalesAreaForCountry(country.code)

    const res = {}

    for (const product of products) {
      const target = getContactMail(product, salesArea) || "other"
      if (!res[target]) {
        res[target] = []
      }
      res[target].push(product.name)
    }

    return res
  }
}
