export const observeDOM = () => {
  const w = window
  const MutationObserver = window.MutationObserver || w.WebKitMutationObserver

  return (obj, callback) => {
    const res = {
      disconnect: () => {},
    }

    if (!obj || obj.nodeType !== 1) {
      return res
    } // validation

    if (MutationObserver) {
      // define a new observer
      const obs = new MutationObserver(() => {
        callback()
      })
      // have the observer observe foo for changes in children
      obs.observe(obj, { childList: true, subtree: true, attributes: true })

      res.disconnect = () => {
        obs.disconnect()
      }

      return res
    } else if (window.addEventListener) {
      obj.addEventListener("DOMNodeInserted", callback, false)
      obj.addEventListener("DOMNodeRemoved", callback, false)

      res.disconnect = () => {
        obj.removeEventListener("DOMNodeInserted", callback)
        obj.removeEventListener("DOMNodeRemoved", callback)
      }

      return res
    }
  }
}
