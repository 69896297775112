import React, { useEffect, useState } from "react"
import { usePopper } from "react-popper"

import { observeDOM } from "../../../util/observe-dom"

export const MenuOverlay = ({
  trigger = null,
  placement = "auto",
  children,
}) => {
  const [popperElement, setPopperElement] = useState(null)

  const { styles, attributes, ...instance } = usePopper(
    trigger,
    popperElement,
    {
      placement,
      strategy: "absolute",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "document",
            altAxis: true,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, -10],
          },
        },
      ],
    },
  )

  useEffect(() => {
    let sub
    const el = document.querySelector("header._fp-main-header")
    if (trigger) {
      sub = observeDOM()(el, () => {
        setTimeout(() => {
          if (instance && instance.update) {
            instance.update()
          }
        }, 250)
      })
    }

    return function cleanup() {
      if (sub) {
        sub.disconnect()
      }
    }
  })

  return (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className={"_fp-popper"}
    >
      {children}
    </div>
  )
}
