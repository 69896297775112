import { Locale } from "./locale"

const localizeLink = (link, key) => {
  const locale = new Locale(key)

  let pathname = link
  let search = ""

  if (link) {
    const match = link.match(/([\/]{0,1}[^?#]*)(\?[^#]*|)$/)
    pathname = match[1].replace(/\/\s*$/, "")
    search = match[2]
  }
  return `/${locale.getLocaleKey()}${pathname}/${search}`
}

export default localizeLink
