import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import CloseIcon from "../../images/icons/close.svg"
import MunzingLogo from "../../images/munzing-logo.svg"
import "./menu-overlay.sass"

const MenuOverlay = ({
  children,
  state,
  setState,
  titleTranslationKey,
  showLogo,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        className={classNames("_fp-menu-overlay", {
          "_fp-menu-overlay--active": state,
        })}
      >
        <div className="_fp-global-container">
          <div className="_fp-menu-overlay__top-bar">
            <button
              onKeyDown={() => setState(false)}
              onClick={() => setState(false)}
              className="_fp-menu-overlay__top-bar__close"
            >
              <CloseIcon />
            </button>
            {titleTranslationKey && (
              <p className="_fp-menu-overlay__top-bar__title">
                {t(titleTranslationKey)}
              </p>
            )}
            {!titleTranslationKey && showLogo && (
              <div className="_fp-menu-overlay__top-bar__logo">
                <MunzingLogo />
              </div>
            )}
          </div>
        </div>
        <div className="_fp-menu-overlay__content">
          <div className="_fp-global-container">{children}</div>
        </div>
      </div>

      <div
        role={"button"}
        aria-label={t("MENU-CLOSE_MENU")}
        className={classNames("_fp-menu-overlay__overlay", {
          "_fp-menu-overlay__overlay--active": state,
        })}
        onClick={() => setState(false)}
        onKeyDown={() => setState(false)}
      />
    </>
  )
}

MenuOverlay.propTypes = {
  showLogo: PropTypes.bool,
  titleTranslationKey: PropTypes.string,
  children: PropTypes.node,
  state: PropTypes.any,
  setState: PropTypes.any,
}

MenuOverlay.defaultProps = {
  showLogo: true,
}

export default MenuOverlay
