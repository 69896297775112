import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import MoreHorizontalIcon from "../../images/icons/more_horizontal.svg"
import "./menu-component.sass"

const DefaultMenuItem = ({ item }) => {
  const { t } = useTranslation()

  return <div>{t(item.translationKey)}</div>
}

class MenuComponent extends React.Component {
  constructor(props) {
    super(props)

    this.subMenuContainerRef = React.createRef()

    this.allItems = this.props.items
    this.itemRefs = this.allItems.map(() => React.createRef())
    this.containerRef = props.containerRef

    this.state = {
      hideAboveIndex: this.allItems.length - 1,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.update()
    }, 100)

    this.resizeListener = () => this.update()

    window.addEventListener("resize", this.resizeListener)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  update() {
    let hideAboveIndex = this.allItems.length - 1

    if (!this.containerRef.current) {
      return
    }
    const containerWidth = this.containerRef.current.offsetWidth
    const widths = this.itemRefs.map((ref) => ref.current?.offsetWidth || 0)

    // padding needed between last menu item (not the "show more" icon) and logo
    let w = 105 + 50

    // hide logos if the container is not wide enough
    for (let i = 0; i < widths.length; i++) {
      w += widths[i]

      if (w > containerWidth) {
        hideAboveIndex = i - 1
        break
      }
    }

    this.setState({
      hideAboveIndex,
    })
  }

  render() {
    const { MenuItemComponent = DefaultMenuItem } = this.props
    const showCollapsedItems =
      this.state.hideAboveIndex + 1 < this.allItems.length

    return (
      <nav className={"_fp-menu"} ref={this.subMenuContainerRef}>
        <div className={"_fp-menu__items-container"}>
          {this.allItems.map((item, index) => {
            const hidden = index > this.state.hideAboveIndex

            return (
              <div
                key={`fp-menu-item_${index}`}
                className={classNames("_fp-menu__item", {
                  "_fp-menu__item--hidden": hidden,
                })}
              >
                <div
                  style={{ display: "inline-block", height: "100%" }}
                  ref={this.itemRefs[index]}
                >
                  <MenuItemComponent
                    item={item}
                    hidden={hidden}
                    subMenuContainer={this.subMenuContainerRef}
                  />
                </div>
              </div>
            )
          })}

          {showCollapsedItems && (
            <div className="_fp-menu__collapsed-items">
              <MenuItemComponent
                item={{
                  children: this.allItems.slice(this.state.hideAboveIndex + 1),
                }}
                subMenuContainer={this.subMenuContainerRef}
              >
                <button className="_fp-menu__collapsed-items__item">
                  <MoreHorizontalIcon />
                </button>
              </MenuItemComponent>
            </div>
          )}
        </div>
      </nav>
    )
  }
}

MenuComponent.propTypes = {
  items: PropTypes.array.isRequired,
  MenuItemComponent: PropTypes.elementType,
}

export default MenuComponent
