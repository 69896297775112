export class GalleryItem {
  type
  descriptionTranslationKey
  constructor({ type, description }) {
    this.type = type
    this.description = description || ""
  }
}

export class GalleryVideoItem extends GalleryItem {
  constructor(props) {
    super({ ...props, type: "VIDEO" })
    this.youtubeId = props.youtubeId
    this.thumbnailSrc = `https://img.youtube.com/vi/${this.youtubeId}/hqdefault.jpg`
  }
}

export class GalleryPictureItem extends GalleryItem {
  constructor(props) {
    super({ ...props, type: "PICTURE" })

    const { gatsbyFluidImage, imgSrc, thumbnailSrc } = props

    this.gatsbyFluidImage = gatsbyFluidImage
    this.imgSrc = imgSrc
    this.thumbnailSrc = thumbnailSrc || imgSrc
  }

  useGatsbyImage() {
    return !!this.gatsbyFluidImage
  }
}

export class GalleryZoomPictureItem extends GalleryItem {
  constructor(props) {
    super({ ...props, type: "ZOOM_PICTURE" })

    const {
      gatsbyFluidImage,
      imgSrc,
      thumbnailSrc,
      zoomedImageSrc,
      width,
      height,
    } = props

    this.width = width
    this.height = height
    this.gatsbyFluidImage = gatsbyFluidImage
    this.imgSrc = imgSrc
    this.zoomedImageSrc = zoomedImageSrc
    this.thumbnailSrc = thumbnailSrc || imgSrc
  }

  useGatsbyImage() {
    return !!this.gatsbyFluidImage
  }
}
