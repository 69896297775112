import React from "react"

import MenuIcon from "../../images/icons/menu.svg"
import "./menu-button.sass"

const MenuButton = ({ state, setState }) => {
  return (
    <button className={"_fp-menu-button"} onClick={() => setState(!state)}>
      {<MenuIcon />}
    </button>
  )
}

export default MenuButton
