import classNames from "classnames"
import React from "react"
import { useWindowScroll } from "react-use"

import useIsActive from "../../hooks/useIsActive"
import LocalizedLink from "../../i18n/localized-link"
import MunzingLogo from "../../images/munzing-logo.svg"
import ProductCartButton from "./go-to-product-cart-button"
import ProductComparisonButton from "./go-to-product-comparison-button"
import "./header.sass"
import LanguageRecommendation from "./language-recommendation/language-recommendation"
import LanguageSwitcher from "./language-switcher/language-switcher"
import MainMenu from "./main-menu/main-menu"

let lastY = 0

const HeaderView = ({ children }) => {
  const { y } = useWindowScroll()

  let isScrolled = lastY <= y ? y > 0 : y > 150
  lastY = y

  const isCareerPage = useIsActive("/career", false)

  return (
    <>
      <header
        className={classNames("_fp-main-header", {
          "_fp-main-header--scrolled": isScrolled,
        })}
      >
        <LanguageRecommendation />
        <div className="_fp-gradient-border" />
        <div className="_fp-global-container">
          <div className="_fp-header-container">
            <div className="_fp-header-container__logo">
              <LocalizedLink
                className={"_fp-header-container__logo__link"}
                to={"/"}
              >
                <MunzingLogo />
              </LocalizedLink>
            </div>
            <MainMenu />
          </div>
        </div>
        <div className="_fp-top-bar">
          <div className="_fp-global-container">
            <div className="_fp-top-bar__container">
              <LanguageSwitcher />
              {!isCareerPage && (
                <div className="_fp-top-bar__container__right">
                  <ProductComparisonButton />
                  <ProductCartButton />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div
        className={classNames("_fp-main-header-view", {
          "_fp-main-header-view--scrolled": isScrolled,
        })}
      >
        {children}
      </div>
    </>
  )
}

export default HeaderView
