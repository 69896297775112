import classNames from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import useIsActive from "../hooks/useIsActive"
import localizeLink from "./localize-link"

const LocalizedLink = React.forwardRef(
  (
    { to, matchUrl, activeClassName, className = "", exactMatch, ...props },
    ref,
  ) => {
    const { i18n } = useTranslation()
    const locale = i18n.language

    const path = localizeLink(to, locale)
    const matchPath = localizeLink(matchUrl || to, locale)

    const isActive = useIsActive(matchPath, exactMatch)

    if (to.startsWith("http")) {
      return (
        <a
          target={"_blank"}
          ref={ref}
          {...props}
          href={to}
          className={classNames(className, isActive ? activeClassName : "")}
        />
      )
    }

    return (
      <Link
        ref={ref}
        {...props}
        to={path}
        className={classNames(className, isActive ? activeClassName : "")}
      />
    )
  },
)

LocalizedLink.propTypes = {
  exactMatch: PropTypes.bool,
  to: PropTypes.string.isRequired,
  matchUrl: PropTypes.string,
  activeClassName: PropTypes.string,
}

LocalizedLink.defaultProps = {
  exactMatch: false,
}

export default LocalizedLink
