/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// needs to be imported first
import "./layout.sass"

import ContactBadge from "../components/contact-badge/contact-badge"
import Footer from "../components/footer/footer"
import HeaderView from "../components/header/headerView"
import i18n from "../i18n/config"
import {
  getLocaleFromLocalStorage,
  setLocaleToLocalStorage,
} from "../i18n/languageStorage"
import { Locale } from "../i18n/locale"
import { BodyAttributesContextProvider } from "../util/body-attributes-context"

const availableLanguages = Locale._allSiteLocaleKeys

const reshapeTranslations = (queriedTranslations) => {
  const res = {}

  for (const lang of availableLanguages) {
    res[lang] = {}
  }

  queriedTranslations.edges.forEach(({ node }) => {
    for (const lang of availableLanguages) {
      res[lang][node.key] = node[`value_${lang}`]
    }
  })

  return res
}

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiTranslation {
        edges {
          node {
            strapiId
            key
            ...TranslationLanguages
          }
        }
      }
    }
  `)

  const translations = reshapeTranslations(data.allStrapiTranslation)

  Object.keys(translations).map((lang) => {
    return i18n.addResourceBundle(lang, "translation", translations[lang])
  })

  if (i18n.language !== pageContext.locale) {
    i18n.changeLanguage(pageContext.locale)
  }

  // If no language is set in the localStorage, set localStorage to browser language
  if (typeof window !== "undefined" && !getLocaleFromLocalStorage()) {
    setLocaleToLocalStorage(window.navigator.language)
  }
  return (
    <BodyAttributesContextProvider>
      <HeaderView siteTitle={data.site.siteMetadata.title}>
        <main>{children}</main>
      </HeaderView>
      <Footer siteTitle={data.site.siteMetadata.title} />
      <ContactBadge />
    </BodyAttributesContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
