import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "react-use"

import LocalizedLink from "../../../i18n/localized-link"
import { useTranslation } from "../../../i18n/translate"
import MegaMenu from "./mega-menu"
import "./menu-item.sass"
import { MenuOverlay } from "./menu-overlay"

const MenuItem = ({
  item,
  children,
  hidden,
  subMenuContainer,
  level = 0,
  placement = "bottom-start",
}) => {
  const { t } = useTranslation()
  const { partiallyActive = true } = item
  const linkRef = useRef()
  const itemRef = useRef()
  const subMenuItemRef = useRef()
  const overlayTargetRef = useRef()

  const hasSubMenu = item.children && item.children.length > 0
  const hasMegaMenu = item.isMegaMenu

  useEffect(() => {
    const itemClicked = () => {
      linkRef.current.blur()
    }

    itemRef.current.addEventListener("click", itemClicked)
    return () => {
      itemRef.current.removeEventListener("click", itemClicked)
    }
  }, [])

  useEffect(() => {
    const resized = () => {
      if (subMenuItemRef.current) {
        subMenuItemRef.current.style.maxHeight =
          window.innerHeight -
          subMenuItemRef.current?.getBoundingClientRect().top -
          16 +
          "px"
      }
    }

    setTimeout(() => {
      resized()
    })

    window.addEventListener("resize", resized)
    return () => {
      window.removeEventListener("resize", resized)
    }
  }, [])

  useEffect(() => {}, [])

  return (
    <>
      <div
        ref={itemRef}
        className={classNames({
          "_fp-main-menu__item": level === 0,
          "_fp-main-menu__item__submenu__item": level !== 0,
          "_fp-main-menu__item__submenu__item--has-submenu":
            hasSubMenu && level !== 0,
        })}
      >
        <div className="_fp-main-menu__item-wrapper" ref={overlayTargetRef}>
          {children ? (
            children
          ) : (
            <LocalizedLink
              ref={linkRef}
              tabIndex={hidden ? -1 : 0}
              className={"_fp-main-menu__link"}
              activeClassName={"_fp-main-menu__link--active"}
              partiallyActive={partiallyActive}
              to={item.url}
              matchUrl={item.srcUrl}
            >
              {t(item.translationKey) || item.translatedString}
            </LocalizedLink>
          )}
        </div>

        {hasMegaMenu && level === 0 && (
          <MegaMenu navItem={item} items={item.children} />
        )}
      </div>

      {hasSubMenu && !hasMegaMenu && (
        // <Submenu submenu={item.children} target={itemRef.current} placement={'bottom-start'}/>
        <MenuOverlay trigger={itemRef.current} placement={placement}>
          <div className={"_fp-main-menu__item__submenu-container"}>
            <div
              ref={subMenuItemRef}
              className={"_fp-main-menu__item__submenu"}
            >
              {(item.translationKey || item.translatedString) && (
                <p
                  className={
                    "_fp-main-menu__item__submenu__title _fp-text _fp-text--slug"
                  }
                >
                  {t(item.translationKey) || item.translatedString}
                </p>
              )}
              {item.children.map((child, index) => {
                return (
                  <MenuItem
                    key={`menu_item_${index}`}
                    item={child}
                    level={level + 1}
                    subMenuContainer={itemRef}
                    placement={"left-start"}
                  />
                )
              })}
            </div>
          </div>
        </MenuOverlay>
      )}
    </>
  )
}

export default MenuItem
