import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import withProductIds from "../../hooks/withProductIds"
import { withTranslation } from "../../i18n/translate"
import CompareIcon from "../../images/icons/comparison.svg"
import Button from "../button/button"

class GoToProductComparisonButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: 0, animation: false }
  }

  componentDidMount() {
    this.updateCheckValue(true)
  }

  componentDidUpdate(prevProps) {
    this.updateCheckValue()
  }

  updateCheckValue(initial = false) {
    const newComparisonLength = this.props.comparison.filter((p) => {
      return this.props.productIds.includes(p.id)
    }).length
    if (this.state.value !== newComparisonLength) {
      const newState = { value: newComparisonLength }

      if (this.state.value < newComparisonLength && !initial) {
        // Trigger animation
        newState.animation = true
        setTimeout(() => {
          this.setState({ animation: false })
        }, 250)
      }

      this.setState(newState)
    }
  }

  render() {
    const { t } = this.props
    return (
      <Button
        buttonLinkPath={"/compare-products/"}
        buttonSvgIconComponent={<CompareIcon />}
        buttonStyle={
          this.state.animation
            ? "terracotta"
            : this.props.showBg
            ? "green-soft"
            : "green-ghost"
        }
        buttonLabel={t("PRODUCTS-PRODUCT_COMPARISON-COMPARE_PRODUCTS_COUNT", {
          postProcess: "interval",
          count: this.state.value,
        })}
      />
    )
  }
}

const mapStateToProps = ({ comparison }) => {
  return {
    comparison: comparison || [],
  }
}

GoToProductComparisonButton.propTypes = {
  showBg: PropTypes.bool,
}

export default withTranslation()(
  connect(mapStateToProps, {})(withProductIds(GoToProductComparisonButton)),
)
