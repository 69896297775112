import classNames from "classnames"
import React from "react"

import useIsActive from "../../hooks/useIsActive"
import LocalizedLink from "../../i18n/localized-link"
import { withTranslation } from "../../i18n/translate"
import trackCustomEvent from "../../util/trackCustomEvent"
import "./contact-badge.sass"

class ContactBadge extends React.Component {
  constructor(props) {
    super(props)

    this.state = { initialized: false, highlight: false }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ initialized: true })
    }, 500)

    this.interval = setInterval(() => {
      this.setState({ highlight: true })
      setTimeout(() => {
        this.setState({ highlight: false })
      }, 1000)
    }, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { t } = this.props
    return (
      <LocalizedLink
        to={"/contact/"}
        className={classNames(
          "_fp-contact-badge",
          { "_fp-contact-badge--initialized": this.state.initialized },
          { "_fp-contact-badge--highlighted": this.state.highlight },
        )}
        onClick={() =>
          trackCustomEvent({
            category: "navigation",
            action: "contact_badge_clicked",
            label: `contact_badge`,
          })
        }
      >
        <div className="_fp-print--hidden _fp-contact-badge__title-wrapper">
          <p
            className={"_fp-contact-badge__title-wrapper__title _fp-heading-4"}
          >
            {t("GLOBAL-CONTACT")}
          </p>
        </div>
      </LocalizedLink>
    )
  }
}

const C = (props) => {
  const isCareerPage = useIsActive("/career", false)

  return <>{!isCareerPage && <ContactBadge {...props} />}</>
}

export default withTranslation()(C)
