import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { onlyOneOf } from "../../util/only-one-of"
import LoadingSpinner from "../loading-spinner/loading-spinner"
import "./button.sass"

const Button = ({
  buttonCallback,
  buttonLabel,
  buttonLinkPath,
  buttonExternalLinkPath,
  buttonSize,
  buttonStyle,
  buttonSvgIconComponent,
  buttonLoading,
  bold,
  ...props
}) => {
  /// Exceptions
  if (!buttonCallback && !buttonLinkPath && !buttonExternalLinkPath) {
    throw new Error(
      "Button component needs buttonCallback or buttonLinkPath or buttonExternalLinkPath prop",
    )
  }
  if (!onlyOneOf([buttonCallback, buttonLinkPath, buttonExternalLinkPath])) {
    throw new Error(
      "Button component can only have one of buttonCallback, buttonLinkPath and buttonExternalLinkPath props",
    )
  }
  ///

  /// Get className for button style
  const getStyleClassName = () => {
    switch (buttonStyle) {
      case "green-soft":
        return "_fp-button--style-green-soft"
      case "red":
        return "_fp-button--style-red"
      case "green-ghost":
        return "_fp-button--style-green-ghost"
      case "white-blue":
        return "_fp-button--style-white-blue"
      case "blue-soft":
        return "_fp-button--style-blue-soft"
      case "disabled":
        return "_fp-button--style-disabled"
      case "disabled-ghost":
        return "_fp-button--style-disabled-ghost"
      case "green":
        return "_fp-button--style-green"
      case "white":
        return "_fp-button--style-white"
      default:
        return "_fp-button--style-green"
    }
  }

  /// Get className for button size
  const getSizeClassName = () => {
    switch (buttonSize) {
      case "small":
        return "_fp-button--size-small"
      case "large":
        return "_fp-button--size-large"
      case "regular":
        return "_fp-button--size-regular"
      default:
        return "_fp-button--size-regular"
    }
  }

  const isDisabled = buttonStyle.includes("disabled")

  const content = (
    <>
      {buttonSvgIconComponent && (
        <div className="_fp-button__icon">{buttonSvgIconComponent}</div>
      )}
      {buttonLabel && (
        <p
          className={classNames("_fp-button__label", {
            "_fp-small-text-paragraph": buttonSize === "small",
          })}
        >
          {buttonLabel}
        </p>
      )}
    </>
  )

  /// Switch between button and link behavior based on prop
  if (buttonCallback) {
    return (
      <button
        {...props}
        tabIndex={isDisabled ? -1 : 0}
        onClick={() => {
          if (buttonCallback && !isDisabled) buttonCallback()
        }}
        className={classNames(
          props.className || "",
          "_fp-button",
          getStyleClassName(),
          getSizeClassName(),
          { "_fp-button--no-label": !buttonLabel },
          { "_fp-button--bold": bold },
        )}
      >
        {content}
      </button>
    )
  } else if (buttonLinkPath) {
    return (
      <LocalizedLink
        {...props}
        tabIndex={isDisabled ? -1 : 0}
        to={buttonLinkPath}
        className={classNames(
          props.className || "",
          "_fp-button",
          getStyleClassName(),
          getSizeClassName(),
          { "_fp-button--no-label": !buttonLabel },
          { "_fp-button--bold": bold },
        )}
      >
        {content}
      </LocalizedLink>
    )
  } else {
    return (
      <a
        {...props}
        tabIndex={isDisabled ? -1 : 0}
        href={buttonExternalLinkPath}
        className={classNames(
          props.className || "",
          "_fp-button",
          getStyleClassName(),
          getSizeClassName(),
          { "_fp-button--no-label": !buttonLabel },
          { "_fp-button--bold": bold },
        )}
      >
        {content}
      </a>
    )
  }
}

Button.propTypes = {
  buttonSize: PropTypes.oneOf(["small", "regular", "large"]),
  buttonStyle: PropTypes.oneOf([
    "white",
    "white-blue",
    "green",
    "red",
    "green-soft",
    "green-ghost",
    "blue-soft",
    "disabled",
    "disabled-ghost",
  ]),
  buttonLabel: PropTypes.string,
  buttonSvgIconComponent: PropTypes.node,
  buttonCallback: PropTypes.func,
  buttonLinkPath: PropTypes.string,
  buttonExternalLinkPath: PropTypes.string,
  buttonLoading: PropTypes.bool,
  bold: PropTypes.bool,
}

Button.defaultProps = {
  buttonSize: "regular",
  buttonStyle: "green",
}

export default Button
